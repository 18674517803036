import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './quote-flow-header.css';

function QuoteFlowHeader({ children, className = '' }) {
  return <h3 className={classNames(className, styles.header)}>{children}</h3>;
}

QuoteFlowHeader.propTypes = {
  className: PropTypes.string,
};

export default QuoteFlowHeader;
