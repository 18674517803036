import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Store from 'store';

import {
  analyticsTrack,
  duplicatePurchaseModalContinueWithDups,
  duplicatePurchaseModalLoaded,
  INITIATE_EXPERIMENT,
  quoteFlowVisitedCheckout,
} from '@pumpkincare/analytics';
import {
  calculateCheckoutTotals,
  getQuoteCompletePets,
  getQuoteEmail,
  getQuoteId,
  getQuotePet,
  getQuotePets,
  getQuotePolicyState,
  getQuoteVetName,
  useQuote,
  useQuotePricing,
} from '@pumpkincare/quotes';
import {
  generateIdentifyMeta,
  getIsLoggedIn,
  useBooleanInput,
} from '@pumpkincare/shared';
import { GRAND_TOTAL_LOCAL_STORAGE_KEY } from '@pumpkincare/shared';
import { LoadingScreen, Trustpilot } from '@pumpkincare/shared/ui';
import { getUserIsChargedAnnually, useUssr } from '@pumpkincare/user';

import { Paths } from '../../../app-shell';
import { setAnalytics } from '../../../quotes';
import { getQuotesActiveId, getQuotesTrackingId } from '../../../quotes/selectors';
import fetchExistingDuplicatePets from '../../../quotes/thunks/fetch-existing-duplicate-pets';
import CheckoutProvider, {
  useIsChargedAnnuallyValue,
  useTotalsSetter,
} from '../checkout-provider';
import Header from '../header';
import CheckoutFAQ from './checkout-faq/checkout-faq';
import CheckoutForm from './checkout-form/checkout-form';
import OneStepCloser from './one-step-closer/one-step-closer';
import DuplicatePurchaseModal from './duplicate-purchase-modal';
import PlanReview from './plan-review';

import styles from './checkout.css';

function Checkout() {
  const dispatch = useDispatch();
  const activeId = useSelector(getQuotesActiveId);
  const trackingId = useSelector(getQuotesTrackingId);

  const [isDuplicatePurchaseModalOpen, toggleDuplicatePurchaseModal] =
    useBooleanInput(false);

  const [dupPets, setDuplicatePets] = useState();
  const setTotals = useTotalsSetter();

  const { data: userData } = useUssr();

  const isLoggedUserAnnuallyCharged = getUserIsChargedAnnually(userData);
  const isLocalAnnuallyCharged = useIsChargedAnnuallyValue();

  const isChargedAnnually = getIsLoggedIn()
    ? isLoggedUserAnnuallyCharged
    : isLocalAnnuallyCharged;

  const priceMultiplier = isChargedAnnually ? 12 : 1;

  const { data: pricingData, isError: isPricingErrors } =
    useQuotePricing(isChargedAnnually);

  const { data: quoteData, isPlaceholderData: isQuotePlaceholderData } = useQuote();
  const quoteId = getQuoteId(quoteData);
  const activePet = getQuotePet(quoteData)(activeId);
  const email = getQuoteEmail(quoteData);
  const policyState = getQuotePolicyState(quoteData);

  const pets = getIsLoggedIn()
    ? getQuotePets(quoteData)
    : getQuoteCompletePets(quoteData);

  const isPrevSelected = pets.some(pet => pet.preventativeSelected);
  const vetName = getQuoteVetName(quoteData);

  const isLoading = isQuotePlaceholderData;

  const {
    insurance_price_after_discount: insurancePriceAfterDiscount,
    total_insurance_price: totalInsurancePrice,
  } = pricingData;

  const { grandTotal, preventTotal } = calculateCheckoutTotals(
    pets,
    totalInsurancePrice,
    priceMultiplier
  );

  const total = preventTotal + insurancePriceAfterDiscount;

  useEffect(() => {
    Store.set(GRAND_TOTAL_LOCAL_STORAGE_KEY, grandTotal);
  }, [grandTotal]);

  function handleIgnoreDuplicatePurchaseModal() {
    analyticsTrack(duplicatePurchaseModalContinueWithDups());

    toggleDuplicatePurchaseModal();
  }

  useEffect(() => {
    if (!isLoading) {
      analyticsTrack(quoteFlowVisitedCheckout(grandTotal, pets));
    }
  }, [pets, grandTotal, isLoading]);

  useEffect(() => {
    if (!dupPets && quoteId) {
      dispatch(fetchExistingDuplicatePets(quoteId)).then(setDuplicatePets);
    }

    if (dupPets?.length > 0) {
      analyticsTrack(duplicatePurchaseModalLoaded());

      toggleDuplicatePurchaseModal();
    }
  }, [dupPets, dispatch, quoteId, toggleDuplicatePurchaseModal]);

  useEffect(() => {
    setTotals({
      insuranceTotal: insurancePriceAfterDiscount,
      preventTotal,
      total,
      grandTotal,
    });
  }, [grandTotal, insurancePriceAfterDiscount, preventTotal, setTotals, total]);

  useEffect(() => {
    if (isPricingErrors) {
      // on checkout page refresh in apld we don't have pet annual_limit and deductible
      dispatch(push(Paths.PlanSelection));
    }
  }, [dispatch, isPricingErrors]);

  useEffect(() => {
    dispatch(
      setAnalytics(generateIdentifyMeta({ CHECKOUT: 'True', email }, trackingId))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading || isPricingErrors ? (
    <LoadingScreen
      petBreedSpecies={activePet?.petBreedSpecies}
      title={`Loading Payment`}
    />
  ) : (
    <>
      <Header />

      {isDuplicatePurchaseModalOpen ? (
        <DuplicatePurchaseModal
          handleContinueWithPurchase={handleIgnoreDuplicatePurchaseModal}
          duplicatePets={dupPets}
        />
      ) : null}

      <div className={styles.checkoutSummary}>
        <div className={styles.left}>
          <div className={styles.mobileVisibility}>
            <OneStepCloser />
          </div>

          <CheckoutForm />

          <div className={styles.desktopVisibility}>
            <CheckoutFAQ
              policyState={policyState}
              vetName={vetName}
              isPrevSelected={isPrevSelected}
            />

            <OneStepCloser />
          </div>
        </div>

        <div className={styles.right}>
          <div className={styles.desktopVisibility}>
            <PlanReview />
          </div>

          <div className={styles.mobileVisibility}>
            <CheckoutFAQ
              policyState={policyState}
              vetName={vetName}
              isPrevSelected={isPrevSelected}
            />
          </div>

          <div className={styles.trustPilot}>
            <Trustpilot widgetType={'badge'} />
          </div>
        </div>
      </div>
    </>
  );
}

export default function CheckoutWithContext() {
  const initError = {
    termsChecked:
      'Looks like you forgot to agree to the policy terms and fraud notice',

    ...(getIsLoggedIn() === false && {
      nameShownOnCard: 'Your name on card field is incomplete.',
      cardNumber: 'Your card number is incomplete.',
      expirationDate: "Your card's expiration date is incomplete.",
      cvc: "Your card's security code is incomplete.",
      paperLessChecked:
        'Looks like you forgot to agree to the paperless consent agreement',
    }),
  };

  return (
    <CheckoutProvider initialErrors={initError}>
      <Checkout />
    </CheckoutProvider>
  );
}
