import classNames from 'classnames';

import { LegalBody } from '@pumpkincare/shared/ui';

import styles from './duplicate-pet.css';

const genderLookup = {
  male: 'Male',
  female: 'Female',
};

const imgBySpeciesAndGender = {
  cat: {
    male: '/assets/images/iconImages/boy-cat.svg',
    female: '/assets/images/iconImages/girl-cat.svg',
  },
  dog: {
    male: '/assets/images/iconImages/boy-dog.svg',
    female: '/assets/images/iconImages/girl-dog.svg',
  },
};

function DuplicatePet({ pet, isDuplicate = false }) {
  return (
    <div
      className={classNames(styles.petCard, {
        [styles.duplicatePetCard]: isDuplicate,
      })}
    >
      <img
        src={
          imgBySpeciesAndGender[pet.petBreedSpecies.toLowerCase()][
            pet.petGender.toLowerCase()
          ]
        }
        alt={'pet icon'}
        className={styles.petCardIcon}
      />
      <div>
        <h4 className={styles.petCardPetName}>{`${pet.petName}'s Plan`}</h4>
        <LegalBody className={styles.petCardPetDetails}>
          {`${genderLookup[pet.petGender.toLowerCase()]} • ${
            pet.petBreedName
          } • Age ${pet.petAge}`}
        </LegalBody>
      </div>
    </div>
  );
}

export default DuplicatePet;
