import { useRef, useState } from 'react';
import Store from 'store';

import { analyticsTrack } from '@pumpkincare/analytics';
import { useMarketingAttribution } from '@pumpkincare/marketing';
import { CjPixel, TunePixel } from '@pumpkincare/marketing/ui';
import { getQuotePolicyZipCode, getQuoteVetId, useQuote } from '@pumpkincare/quotes';
import {
  COMPLETE_QUOTE_ID_LS_KEY,
  GRAND_TOTAL_LOCAL_STORAGE_KEY,
} from '@pumpkincare/shared';
import { Body1, LoaderButton, Radio, Select } from '@pumpkincare/shared/ui';
import { VetClinic } from '@pumpkincare/vets/ui';

import PepUpsell from './pep-upsell/pep-upsell';
import useSubmitHdyhau from './use-submit-hdyhau';

import styles from './hdyhau.css';

const MY_VET_CLINIC = 'MY_VET_CLINIC';
const OPTIONS = [
  { value: MY_VET_CLINIC, label: 'My Vet Clinic' },
  { value: 'MY_EMPLOYER', label: 'My Employer' },
  { value: 'BREEDER_OR_SHELTER', label: 'Breeder/Shelter' },
  { value: 'GOOGLE_OR_AD', label: 'Google/Search Ad' },
  { value: 'SOCIAL_MEDIA', label: 'Social Media' },
  { value: 'TELEVISION', label: 'Television' },
  { value: 'FRIEND_OR_FAMILY', label: 'Friend/Family' },
  { value: 'SNAIL_MAIL', label: 'Snail Mail' },
  { value: 'PET_INSURANCE_REVIEW_SITE', label: 'Pet Insurance Review Site' },
];
const OPTION_OTHER = { value: 'OTHER', label: 'Other' };

function Hdyhau() {
  const sortedOptions = useRef([
    ...OPTIONS.sort(() => Math.random() - 0.5),

    OPTION_OTHER,
  ]);

  const grandTotal = Store.get(GRAND_TOTAL_LOCAL_STORAGE_KEY);
  const quoteId = Store.get(COMPLETE_QUOTE_ID_LS_KEY);

  const { data: quoteData } = useQuote({ quoteId });
  const quoteZipCode = getQuotePolicyZipCode(quoteData);
  const vetId = getQuoteVetId(quoteData);

  const [hdyhauOptionSelected, setHdyhauOptionSelected] = useState(null);
  const [selectedVet, setSelectedVet] = useState(null);

  const { submit: submitHdyhau, isLoading } = useSubmitHdyhau(vetId);
  const isFirstVisitWithAttribution = useMarketingAttribution(
    quoteId,
    'Land on Checkout Success',
    'isGoingToCheckoutSuccess'
  );
  const isMyVetClinic = hdyhauOptionSelected === MY_VET_CLINIC;

  function handleRadioClick(event) {
    setHdyhauOptionSelected(event.target.value);
  }

  function handleSelectClick(event) {
    setHdyhauOptionSelected(event?.value);
  }

  function handleVetSelect(vet) {
    setSelectedVet(vet);
  }

  function isCtaDisabled() {
    if (isMyVetClinic) {
      return !selectedVet || !selectedVet.valid;
    }

    return !hdyhauOptionSelected;
  }

  function handleNextClick() {
    let order = 0;
    const hdyhauOptionValue = sortedOptions.current.find(option => {
      order++;
      return option.value === hdyhauOptionSelected;
    }).label;

    analyticsTrack({
      category: 'Vet Form',
      event: 'Submit HDYHAU',
      answer: hdyhauOptionValue,
      order,
    });

    submitHdyhau({
      vet: selectedVet?.existing,
      hdyhauOptionSelected,
      hdyhauOptionValue,
      order,
    });
  }

  return (
    <div className={styles.mainContainer}>
      <h1 className={styles.header}>Yay, you’re enrolled!</h1>

      <div className={styles.container}>
        <div className={styles.leftSide}>
          <Body1 isBold className={styles.question}>
            First a quick question...
            <br />
            How did you hear about Pumpkin?
          </Body1>

          <div className={styles.checkbox}>
            {sortedOptions.current.map(option => (
              <div key={option.value} className={styles.checkboxItem}>
                <Radio
                  small
                  key={option.value}
                  value={option.value}
                  label={<Body1>{option.label}</Body1>}
                  classes={{
                    root: styles.radio,
                  }}
                  onChange={handleRadioClick}
                  checked={option.value === hdyhauOptionSelected}
                />
              </div>
            ))}
          </div>

          <div className={styles.select}>
            <Select
              isSearchable
              onChange={e => handleSelectClick(e)}
              placeholder={
                hdyhauOptionSelected
                  ? sortedOptions.current.find(
                      obj => obj.value === hdyhauOptionSelected
                    ).label
                  : 'Select how you heard of us!'
              }
              options={sortedOptions.current}
              defaultValue={hdyhauOptionSelected}
              id={'id-select-option'}
              autoFocus
            />
          </div>

          {hdyhauOptionSelected === MY_VET_CLINIC ? (
            <VetClinic
              className={styles.vetClinic}
              onChange={handleVetSelect}
              zipCode={quoteZipCode}
              canShowForm={false}
            />
          ) : null}

          <LoaderButton
            disabled={isCtaDisabled()}
            classes={{ root: styles.ctaButton }}
            color={'primary'}
            onClick={handleNextClick}
            data-testid='submit-button'
            isLoading={isLoading}
          >
            Next
          </LoaderButton>
        </div>

        <div className={styles.rightSide}>
          <PepUpsell />
        </div>

        {isFirstVisitWithAttribution ? (
          <>
            <CjPixel page='Purchase' quoteId={quoteId} checkoutTotal={grandTotal} />
            <TunePixel page='Sale' quoteId={quoteId} checkoutTotal={grandTotal} />
          </>
        ) : null}
      </div>
    </div>
  );
}

export default Hdyhau;
