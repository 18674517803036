import { Body2, Modal } from '@pumpkincare/shared/ui';

import DuplicatePet from './duplicate-pet';

import styles from './duplicate-purchase-modal.css';

function DuplicatePurchaseModal({ handleContinueWithPurchase, duplicatePets }) {
  return (
    <Modal onClose={handleContinueWithPurchase} classeName={styles.container}>
      <div className={styles.errorImg}>
        <img
          src={'/assets/images/iconImages/alert-triangle.svg'}
          alt={'error alert triangle'}
        />
      </div>
      <h4 className={styles.headingText}>Looks like you already purchased a plan</h4>
      <Body2 className={styles.subHeadingText}>
        A similar transaction was recently completed.
      </Body2>
      <div className={styles.content}>
        <div className={styles.contentItem} style={{ margin: 0 }}>
          <p className={styles.petCardLabel}>Existing Plan:</p>
          {duplicatePets.map((pet, index) => (
            <div key={index}>
              <DuplicatePet pet={pet.existingPet} />
            </div>
          ))}
        </div>
        <div className={styles.contentItem} style={{ margin: 0 }}>
          <p className={styles.petCardLabel}>Duplicate Plan:</p>
          {duplicatePets.map((pet, index) => (
            <div key={index}>
              <DuplicatePet pet={pet.duplicatePet} isDuplicate={true} />
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
}

export default DuplicatePurchaseModal;
