// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".checkout-faq_linkFont_8bc345ff {\n    color: var(--bluesCluesBlue);\n}\n", "",{"version":3,"sources":["webpack://src/checkout/view/checkout/checkout-faq/checkout-faq.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;AAChC","sourcesContent":[".linkFont {\n    color: var(--bluesCluesBlue);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"linkFont": "checkout-faq_linkFont_8bc345ff"
};
export default ___CSS_LOADER_EXPORT___;
