import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { getIsLoggedIn } from '@pumpkincare/shared';
import { Trustpilot } from '@pumpkincare/shared/ui';

import { getQuotesPolicyState } from '../../../../quotes/selectors';
import { useTotalsValue } from '../../checkout-provider';
import SslTrustIcon from '../../ssl-trust-icon';
import PlanReview from '../plan-review';
import PurchaseButton from '../purchase-button';
import BillingForm from './billing-form';
import Paperless from './paperless';
import PaymentForm from './payment-form';
import ShippingForm from './shipping-form';
import Terms from './terms';

import styles from './checkout-form.css';

function CheckoutForm() {
  const { grandTotal } = useTotalsValue();
  const quotePolicyState = useSelector(getQuotesPolicyState);

  const [isStickButton, setIsStickButton] = useState(false);
  const btnSectionRef = useRef();

  const buttonWrapperClass = classNames({
    [styles.fixedButtonWrapper]: isStickButton,
    [styles.buttonWrapper]: !isStickButton,
  });

  const sectionRefClass = classNames(styles.sectionRef, {
    [styles.sectionRefSticky]: isStickButton,
  });

  const buttonSectionClass = classNames({
    [styles.buttonSection]: !isStickButton,
    [styles.buttonSectionSticky]: isStickButton,
  });

  useEffect(() => {
    function handleScroll() {
      if (btnSectionRef.current) {
        const { offsetTop } = btnSectionRef.current;
        setIsStickButton(window.scrollY > offsetTop);
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [btnSectionRef]);

  return (
    <div className={styles.checkoutContainer}>
      <form autoComplete='on' method='post'>
        <h4 className={styles.accountTitle}>Account Address</h4>

        <ShippingForm />

        <div className={styles.mobileVisibility}>
          <PlanReview />

          <div className={styles.trustIcons}>
            <Trustpilot widgetType={'badge'} />
          </div>
        </div>

        <h4 style={{ margin: '60px 0 0' }}>Card Info</h4>

        <PaymentForm />

        <div className={styles.trustIcons}>
          {quotePolicyState !== 'NY' ? (
            <img
              src={'/assets/images/iconImages/mbg-seal.png'}
              alt={'money back guarantee icon'}
            />
          ) : null}

          <SslTrustIcon />
        </div>

        {getIsLoggedIn() ? null : (
          <>
            <h4 style={{ margin: '60px 0 0' }}>Billing Address</h4>

            <BillingForm />
          </>
        )}

        {getIsLoggedIn() ? null : <Paperless />}

        <Terms />

        <div ref={btnSectionRef} className={sectionRefClass} />

        <div className={buttonSectionClass}>
          <div className={buttonWrapperClass}>
            <PurchaseButton
              classes={{ root: styles.checkoutButton }}
              grandTotal={grandTotal}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default CheckoutForm;
