// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".quote-flow-header_header_c612b912 {\n    margin: 0 0 16px;\n    text-align: center;\n}", "",{"version":3,"sources":["webpack://src/quotes/view/quote-flow-header/quote-flow-header.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".header {\n    margin: 0 0 16px;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "quote-flow-header_header_c612b912"
};
export default ___CSS_LOADER_EXPORT___;
